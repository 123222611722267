// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
require("trix")
require("@rails/actiontext")


import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "activeadmin_addons"




console.log('loaded 2 3 4')
